<template lang="pug">
    #landing
</template>

<script>
import LandingBanner from "@/components/landing/landing-banner/landing-banner";
export default {
    components: { LandingBanner },
};
</script>
